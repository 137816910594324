<template>
  <div class="detail-page reverse">
    <div class="detail-page__left">
      <CardLeftBlock
          :name="'Subuser of'"
          :backgroundImage="'subadmin'"
      >
        <template slot="body">
          <div class="card-detail-left__mail">
            Iryna Kovalska
          </div>
          <div class="card-detail-left__date mt-1">
            <span class="soft-text">
              27 Aug, 2021
            </span>
          </div>
        </template>
      </CardLeftBlock>
    </div>

    <div class="detail-page__right">
      <CardRightBlock>
        <template slot="header">
          <div class="order-create__head">
            <div class="order-create__head-line">
              <div class="order-create__header-col">
                <router-link :to="$store.getters.GET_PATHS.mainSettingsUsers" class="detail-page__head-back">
                  <LinkBack
                      :value="'Back to All Shops' "
                  />
                </router-link>
              </div>
            </div>
            <div class="order-create__head-title">
              Create a Subuser
            </div>
            <div class="order-create__head-txt mb-0">
              Please, fill out all the information below to create a subuser
            </div>
          </div>
        </template>

        <template slot="body">
          <form class="order-create__section">
            <div class="order-create__section-label section-label">
              General Information
            </div>

            <div class="order-create__row custom-row">
              <div class="order-create__col custom-col custom-col--33 custom-col--md-50 custom-col--sm-100">
                <DefaultInput
                    :label="'Name'"
                    :type="'text'"
                    v-model="name"
                />
              </div>
              <div class="order-create__col custom-col custom-col--33 custom-col--md-50 custom-col--sm-100">
                <DefaultInput
                    :label="'Family Name'"
                    :type="'text'"
                    v-model="fname"
                />
              </div>
              <div class="order-create__col custom-col custom-col--33 custom-col--md-50 custom-col--sm-100">
                <DefaultInput
                    :label="'Password'"
                    :type="'password'"
                    v-model="pname"
                    :autocomplete="'off'"
                />
              </div>
              <div class="order-create__col custom-col custom-col--33 custom-col--md-50 custom-col--sm-100">
                <DefaultInput
                    :label="'Phone'"
                    :type="'text'"
                    v-model="ppname"
                />
              </div>
              <div class="order-create__col custom-col custom-col--33 custom-col--md-50 custom-col--sm-100">
                <DefaultInput
                    :label="'Email'"
                    :type="'text'"
                    v-model="ename"
                />
              </div>
            </div>

            <div class="order-create__section-label section-label">
              Permission

              <v-popover
                  class="site-tooltip site-tooltip--radio-after"
                  :disabled="!tooltipAddProductActive"
                  offset="5"
                  placement="top-left"
                  trigger="hover"

              >

                <TooltipBtn/>

                <template slot="popover">
                  <p>
                    <b>Random title</b>
                  </p>
                  <p>
                    Payment from Paypal is a default functionality in the system, Adding payment through Autorize.net you add the payments from VISA, Mastercard accounts
                  </p>
                </template>
              </v-popover>
            </div>

            <div class="order-create__row custom-row">
              <div class="order-create__col custom-col custom-col--33 custom-col--md-50 custom-col--sm-100 d-flex flex-column">
                <DefaultCheckbox
                    class="mb-3"
                    :label="'PayPal Transactions'"
               />
                <DefaultCheckbox
                    class="mb-3"
                    :label="'Products'"
               />
                <DefaultCheckbox
                    class="mb-3"
                    :label="'Returns'"
               />
                <DefaultCheckbox
                    class="mb-3"
                    :label="'Orders FBM'"
               />
              </div>
              <div class="order-create__col custom-col custom-col--33 custom-col--md-50 custom-col--sm-100 d-flex flex-column">
                <DefaultCheckbox
                    class="mb-3"
                    :label="'Orders Ukrposhta'"
               />
                <DefaultCheckbox
                    class="mb-3"
                    :label="'Shipment'"
               />
                <DefaultCheckbox
                    class="mb-3"
                    :label="'Orders DHL'"
               />
                <DefaultCheckbox
                    class="mb-3"
                    :label="'Orders Etsy'"
               />
              </div>
              <div class="order-create__col custom-col custom-col--33 custom-col--md-50 custom-col--sm-100 d-flex flex-column">
                <DefaultCheckbox
                    class="mb-3"
                    :label="'Orders FedEx'"
               />
                <DefaultCheckbox
                    class="mb-3"
                    :label="'Orders TNT'"
               />
                <DefaultCheckbox
                    class="mb-3"
                    :label="'Orders Amazon'"
               />
                <DefaultCheckbox
                    class="mb-3"
                    :label="'Shipment to Warehouse'"
               />
              </div>
            </div>


          </form>
        </template>
        <template slot="footer">
          <div class="order-create__footer">
            <div class="order-create__footer-link">
              <router-link :to="$store.getters.GET_PATHS.mainSettingsUsers"
                           class="site-link site-link--alt"
              >
                Cancel
              </router-link>

            </div>

            <div class="order-create__footer-btn ml-auto">
              <MainButton
                  class="order-create__footer-btn-i"
                  :value="'Create'"
              />
            </div>
          </div>
        </template>
      </CardRightBlock>

    </div>

  </div>
</template>

<script>
  import CardLeftBlock from "../../../../../components/coreComponents/CardLeftBlock/CardLeftBlock.vue";
  import MainButton from "../../../../../components/UI/buttons/MainButton/MainButton";
  import CardRightBlock from "../../../../coreComponents/CardRightBlock/CardRightBlock.vue";
  import LinkBack from "../../../../UI/buttons/LinkBack/LinkBack.vue";
  import {mixinDetictingMobile} from "../../../../../mixins/mobileFunctions";
  import DefaultInput from "../../../../UI/inputs/DefaultInput/DefaultInput";
  import DefaultCheckbox from "../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
  import TooltipBtn from "../../../../UI/tooltips/TooltipBtn/TooltipBtn";
  import {VPopover} from "v-tooltip";



  export default {
    name: "SubUserCreation",

    components: {
      CardLeftBlock,
      MainButton,
      CardRightBlock,
      LinkBack,
      DefaultInput,
      DefaultCheckbox,
      TooltipBtn,
      VPopover,
    },

    mixins: [mixinDetictingMobile],

    data() {
      return {

        transactionNumber: '3847598fu9e8498w7',
        valueSum: '',
        options: [{}],
        files: [],
        text: 'Please be careful with the vase inside! ',
        tooltipAddProductActive: true,
        name: '',
        fname: '',
        pname: '',
        ppname: '',
        ename: '',
      }
    },

    methods: {

    }
  }
</script>

<style scoped>

</style>

